import dayjs from 'dayjs'
import app from '../callFunction/index'
//返回会员级别
export const Vip_type = (type_num) => {
    return type_num == 1 ? '普通会员' :
        type_num == 0 ? '3天试用期会员' :
        type_num == 2 ? '外派企业' :
        type_num == 3 ? '月度会员' :
        type_num == 4 ? '季度会员' :
        type_num == 5 ? '半年会员' :
        type_num == 6 ? '年度会员' :
        type_num == 7 ? '高级年度会员' :
        type_num == 8 ? '至尊年度会员' : ''
}

//返回图片路径显示
export const img_url = (store_url) => {
    //旧数据有数组类型
    store_url = store_url instanceof Array ? store_url[0] : store_url
        //判断是否线上线下环境
    let new_url
    if (app.cloudapp.cloudbaseConfig.env == 'test-1po4v') {
        //线下
        new_url = store_url.replace('cloud://test-1po4v.7465-test-1po4v-1303180979', 'https://7465-test-1po4v-1303180979.tcb.qcloud.la');
        return new_url

    } else {
        new_url = store_url.replace('cloud://hphronline-0gf0t91ufecc02db.6870-hphronline-0gf0t91ufecc02db-1303180979', 'https://6870-hphronline-0gf0t91ufecc02db-1303180979.tcb.qcloud.la');
        return new_url
            //线上
    }
}

//针对固定为单张图片
export const only_img_url = (store_url) => {
    return new_url
}

//导出数据过滤
export const export_data = (arr) => {

    let new_arr = arr.filter((item, index) => {
        item.date = '="' + dayjs(item.date).format("YYYY-MM-DD") + '"'
        item.status = item.status == 3 ? '审核通过' : item.status == 1 ? '注册待审' : '审核不通过'
        if (item.registerArea) {
            item.registerArea = item.registerArea.replace(/,/g, '');
        }

        item.positions = item.positions ? '是' : '否'
        item.creditCode = '="' + item.creditCode + '"'
        item.publishDate = '="' + dayjs(item.publishDate).format("YYYY-MM-DD") + '"'
        if (item.admins) {
            item.admins_email = item.admins[0].email
            item.admins_name = item.admins[0].name
            item.admins_department = item.admins[0].department
            item.admins_position = item.admins[0].position
            item.admins_mobile = item.admins[0].mobile
        }

        if (item.description) {
            item.description = item.description.replace(/,/g, '，');
        }

        item.enterprise_name = item.enterprise.name

        return item
    })

    return new_arr

}


//校验社会信用代码
export const CheckSocialCreditCode = (Code) => {
    var patrn = /^[0-9A-Z]+$/;
    //18位校验及大写校验  
    if ((Code.length != 18) || (patrn.test(Code) == false)) {
        return false;
    } else {
        var Ancode; //统一社会信用代码的每一个值  
        var Ancodevalue; //统一社会信用代码每一个值的权重   
        var total = 0;
        var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]; //加权因子   
        var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
        //不用I、O、S、V、Z   
        for (var i = 0; i < Code.length - 1; i++) {
            Ancode = Code.substring(i, i + 1);
            Ancodevalue = str.indexOf(Ancode);
            total = total + Ancodevalue * weightedfactors[i];
            //权重与加权因子相乘之和   
        }
        var logiccheckcode = 31 - total % 31;
        if (logiccheckcode == 31) {
            logiccheckcode = 0;
        }
        var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
        var Array_Str = Str.split(',');
        logiccheckcode = Array_Str[logiccheckcode];
        var checkcode = Code.substring(17, 18);
        if (logiccheckcode != checkcode) {
            return false;
        }
        return true;
    }
}