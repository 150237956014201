/*根据出生日期算出年龄*/ //传参的格式为 2000-01-10
function jsGetAge(strBirthday) {
    if (strBirthday) {
        var returnAge;
        var strBirthdayArr = strBirthday.split("-");
        var birthYear = strBirthdayArr[0];
        var birthMonth = strBirthdayArr[1];
        var birthDay = strBirthdayArr[2];
        var d = new Date();
        var nowYear = d.getFullYear();
        var nowMonth = d.getMonth() + 1;
        var nowDay = d.getDate();

        if (nowYear == birthYear) {
            returnAge = 0; //同年 则为0岁
        } else {
            var ageDiff = nowYear - birthYear; //年之差
            if (ageDiff > 0) {
                if (nowMonth == birthMonth) {
                    var dayDiff = nowDay - birthDay; //日之差
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birthMonth; //月之差
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                }
            } else {
                returnAge = ''; //返回-1 表示出生日期输入错误 晚于今天
            }
        }
        return returnAge; //返回周岁年龄
    } else {
        return ''
    }

}

/*根据参加工作日获取工作年龄*/
const jsGetworkedDate = (data) => {
    if (data) {
        var y = new Date().getFullYear();
        let newdata = data[0] + data[1] + data[2] + data[3]
        return y - newdata
    } else {
        return ''
    }



}


// 导出 {常量名、函数名}
export { jsGetAge, jsGetworkedDate }