import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'dayjs'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import app from './callFunction/index'
import { Vip_type, export_data, img_url } from './libs/Fun'
import { jsGetAge } from './libs/util'



// import './libs/iview_components' 
import './assets/icon_custom/iconfont.css'
import $ from 'jquery'

Vue.use(ViewUI);
Vue.use($);

Vue.prototype.app = app.app
Vue.prototype.upLoad = app.upLoad

Vue.prototype.dayjs = dayjs
Vue.prototype.Vip_type = Vip_type
Vue.prototype.export_data = export_data
Vue.prototype.img_url = img_url
Vue.prototype.jsGetAge = jsGetAge
//图片异常返回自定义图像
Vue.prototype.errorImg01 = require('@/assets/images/admin_logo.png')//处理文件的url 

Vue.config.productionTip = false

Vue.filter('dayjs', function (value, format) {
  if (!format) {
    format = 'YYYY-MM-DD'
  }
  return dayjs(value).format(format)
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
