import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    { //登录页面
        path: '/',
        name: 'login',
        meta: {
            title: '登录',
        },
        component: () =>
            import ('@/views/login/login.vue')
    },
    { //注册页
        path: '/Register',
        name: 'Register',
        meta: {
            title: '注册',
        },
        component: () =>
            import ('@/views/Register/Register.vue')
    },
    { //忘记密码
        path: '/forgetPassword',
        name: 'forgetPassword',
        meta: {
            title: '忘记密码',
        },
        component: () =>
            import ('@/views/forgetPassword/forgetPassword.vue')
    },
    { //首页
        path: '/Home',
        name: 'Home',
        redirect: '/Console',
        meta: {
            title: '首页',
        },
        component: () =>
            import ('@/views/Home/Home.vue'),
        children: [{ //总控制台
                path: '/Console',
                name: 'Console',
                meta: {
                    title: '总控制台',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/Console/Console.vue')
            },


            //-------------
            { //职位管理
                path: '/job_manage',
                name: 'job_manage',
                meta: {
                    title: '职位管理',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/job_manage/job_manage.vue')
            },
            { //全职职位发布
                path: '/job_full',
                name: 'job_full',
                meta: {
                    title: '全职职位发布',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/job_manage/issue_edit/job_full.vue')
            },
            { //小时工职位发布
                path: '/job_Temporary',
                name: 'job_Temporary',
                meta: {
                    title: '小时工职位发布',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/job_manage/issue_edit/job_Temporary.vue')
            },



            //------------
            // {//简历管理
            //   path: '/resume_manage',
            //   name: 'resume_manage',
            //   meta: {
            //     title: '简历管理',
            //   },
            //   component: () => import('@/views/Home/Menu_page/resume_manage/resume_manage.vue')
            // },
            { //简历管理
                path: '/Management',
                name: 'Management',
                meta: {
                    title: '简历管理',
                    keepAlive: true
                },
                component: () =>
                    import ('@/views/Home/Menu_page/Management/Management.vue')
            },
            { //简历管理详情
                path: '/resume_manage_detail',
                name: 'resume_manage_detail',
                meta: {
                    title: '简历管理详情',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/resume_manage/resume_manage_detail.vue')
            },

            { //面试管理
                path: '/interview_manage',
                name: 'interview_manage',
                meta: {
                    title: '面试管理',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/interview_manage/interview_manage.vue')
            },
            { //面试管理
                path: '/interview_manage_detail',
                name: 'interview_manage_detail',
                meta: {
                    title: '面试邀约详情',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/interview_manage/interview_manage_detail.vue')
            },
            { //人才列表
                path: '/talents_list',
                name: 'talents_list',
                meta: {
                    title: '人才列表',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/talents_list/talents_list.vue')
            },
            { //人才详情
                path: '/talents_details',
                name: 'talents_details',
                meta: {
                    title: '人才详情',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/talents_list/talents_details/talents_details.vue')
            },
            { //人才管理
                path: '/talents_manage',
                name: 'talents_manage',
                meta: {
                    title: '人才管理',
                    keepAlive: true

                },
                component: () =>
                    import ('@/views/Home/Menu_page/talents_manage/talents_manage.vue')
            },
            // { //线下招聘会
            //     path: '/job_fair',
            //     name: 'job_fair',
            //     meta: {
            //         title: '线下招聘会',
            //     },
            //     component: () =>
            //         import ('@/views/Home/Menu_page/job_fair/job_fair.vue')
            // },
            { //新线下招聘会
                path: '/job_fair_new',
                name: 'job_fair_new',
                meta: {
                    title: '线下招聘会',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/job_fair_new/job_fair_new.vue')
            },
            // { //线下招聘会详情
            //     path: '/job_fair_detail',
            //     name: 'job_fair_detail',
            //     meta: {
            //         title: '招聘简章填写',
            //     },
            //     component: () =>
            //         import ('@/views/Home/Menu_page/job_fair/job_fair_detail.vue')
            // },
            { //新线下招聘会详情
                path: '/job_fair_details_new',
                name: 'job_fair_details_new',
                meta: {
                    title: '招聘简章填写',
                    keepAlive: true
                },
                component: () =>
                    import ('@/views/Home/Menu_page/job_fair_new/job_fair_details/job_fair_details_new.vue')

            },
            // { //招聘会导入页面
            //     path: '/job_fair_import',
            //     name: 'job_fair_import',
            //     meta: {
            //         title: '导入历史职位',
            //     },
            //     component: () =>
            //         import ('@/views/Home/Menu_page/job_fair_new/job_fair_import/job_fair_import.vue')

            // },
            { //导入历史职位
                path: '/job_fair_oldPosition',
                name: 'job_fair_oldPosition',
                meta: {
                    title: '导入历史职位',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/job_fair/job_fair_oldPosition.vue')
            },
            { //个人中心
                path: '/person',
                name: 'person',
                meta: {
                    title: '个人中心',
                    keepAlive: true
                },
                component: () =>
                    import ('@/views/Home/Menu_page/person/person.vue')
            },
            { //更换手机号
                path: '/changePhone',
                name: 'changePhone',
                meta: {
                    title: '更换手机号',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/person/changePhone/changePhone.vue')
            },
            { //修改密码
                path: '/changePassword',
                name: 'changePassword',
                meta: {
                    title: '修改密码',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/person/changePassword/changePassword.vue')
            },
            { //企业资料
                path: '/firm_data',
                name: 'firm_data',
                meta: {
                    title: '企业资料',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/firm_data/firm_data.vue')
            },
            { //企业资料详情
                path: '/firm_data_detail',
                name: 'firm_data_detail',
                meta: {
                    title: '企业资料详情',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/firm_data/firm_data_detail.vue')
            },
            { //我的消息
                path: '/my_message',
                name: 'my_message',
                meta: {
                    title: '我的消息',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/my_message/my_message.vue')
            },
            { //联系客服
                path: '/kefu',
                name: 'kefu',
                meta: {
                    title: '联系客服',
                },
                component: () =>
                    import ('@/views/Home/Menu_page/kefu/kefu.vue')
            },

        ]
    },
    { //协议
        path: '/agreement',
        name: 'agreement',
        meta: {
            title: '用户协议',
        },
        component: () =>
            import ('@/views/login/agreement.vue')
    },
    { //隐私
        path: '/privacy',
        name: 'privacy',
        meta: {
            title: '用户协议',
        },
        component: () =>
            import ('@/views/login/privacy.vue')
    },

]



const router = new VueRouter({
    routes
})

// 重复点击路由报错解决
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("firm_token")
    if (!token && to.name !== 'login' && to.name !== 'Register' && to.name !== 'forgetPassword' && to.name !== 'agreement' && to.name !== 'privacy') {
        next({
            name: 'login' // 跳转到登录页
        })
    } else {
        next()
    }
})

export default router