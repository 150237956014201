import cloudbase from "@cloudbase/js-sdk";
import router from '../router'
import { Message, Notice } from 'view-design'

const cloudapp = cloudbase.init({
    // env: 'test-1po4v',
    env: 'hphronline-0gf0t91ufecc02db',
});

cloudapp.auth().signInAnonymously()


const app = (name, func, data) => {
    data.token = localStorage.getItem("firm_token")
    return cloudapp.callFunction({
        name,
        data: {
            func,
            data,
        },
        parse: true
    }).then(res => {
        // console.log(res);
        let code = res.result.code

        //  404 "您的长期未操作,登录已过时,请前往重新登录"
        if (code == 401 || code == 404) {
            console.log("登录去");
            router.push('/');
        } else {
            if (code != 200) {
                Message.error({
                    content: res.result.message,
                    closable: true,
                    duration: 5
                })
            }
            return res.result

        }
    })
}


const upLoad = (data) => {

    return cloudapp.uploadFile(data).then(res => {
        return res
    })
}

export default { app, upLoad, cloudapp }