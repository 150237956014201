import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Table_vuex: [],
    },
    mutations: {
        changeTable_vuex(state, new_Table) {
            state.Table_vuex = new_Table
        },

    },
    actions: {},
    modules: {}
})